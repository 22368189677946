/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import NextLink from "next/link";
import { ElementType, FunctionComponent } from "react";

import { BackgroundThemeKeys, TextThemeKeys } from "@/types/tokens/themes";

import { Color, Colors } from "@/tokens/color";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamily } from "@/tokens/fonts";

import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    generateButtonBoxModelStyles,
    generateButtonTextPaddingStyles,
} from "@/util/tokens/button_util";
import { TextColor } from "@/util/tokens/typography_util";

interface FlagProps {
    backgroundColor?: Color;
    backgroundThemeKey?: BackgroundThemeKeys;
    children: React.ReactNode;
    className?: SerializedStyles;
    color?: TextColor;
    fontFamily?: FontFamily;
    fontSize?: FontStyleSlug;
    href?: string;
    isUppercase?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    tag?: ElementType | "Link";
    textThemeKey?: TextThemeKeys;
}

export const Flag: FunctionComponent<FlagProps> = ({
    backgroundThemeKey = "backgroundTertiary",
    fontFamily = "sans",
    fontSize = "LabelDefault",
    isUppercase = false,
    textThemeKey = "textPrimary",
    ...props
}) => {
    /**
     * Globals
     */
    /**
     * Styles
     */
    const theme = useTypedTheme();

    const flagStyles = css(
        {
            background:
                Colors[
                    props.backgroundColor ||
                        theme.backgrounds[backgroundThemeKey]
                ],
            cursor:
                props.tag === "Link" ||
                props.tag === "a" ||
                props.tag === "button"
                    ? "pointer"
                    : "default",
        },
        generateButtonBoxModelStyles(fontSize),
        generateButtonTextPaddingStyles(fontSize),
        props.className,
    );

    /**
     * Rendering
     */
    if (props.tag === "Link") {
        if (!props.href) {
            return null;
        }
        return (
            <NextLink
                css={css(flagStyles, { textDecoration: "none" })}
                href={props.href}
                text-decoration="none"
            >
                <Text
                    color={props.color}
                    fontFamily={fontFamily}
                    fontSize={fontSize}
                    fontWeight="medium"
                    isUppercase={isUppercase}
                    labelLineHeight={true}
                    themeKey={textThemeKey}
                >
                    {props.children}
                </Text>
            </NextLink>
        );
    }
    return (
        <Text
            className={flagStyles}
            color={props.color}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight="medium"
            isUppercase={isUppercase}
            labelLineHeight={true}
            tag={props.tag}
            themeKey={textThemeKey}
            onClick={props.onClick}
        >
            {props.children}
        </Text>
    );
};
