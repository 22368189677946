/** @jsxImportSource @emotion/react */
import { FunctionComponent } from "react";

import { GridContainer } from "@/ui/atoms/grid_container";
import { Text } from "@/ui/atoms/text";
import { BlogArchiveArticleRow } from "@/ui/organisms/blog_archive_article_row";
import { BlogArticleSnippet } from "@/ui/organisms/blog_article_card";

interface BlogArticleCardListProps {
    articles: BlogArticleSnippet[];
}

export const BlogArchiveArticleList: FunctionComponent<
    BlogArticleCardListProps
> = (props) => {
    /**
     * Elements
     */
    return (
        <>
            <GridContainer marginTop="BlogArchiveArticleListMargin">
                <Text
                    columnSpan="full"
                    fontSize="BlogArchiveSectionTitle"
                    marginBottom="BlogArchiveArticleListHeadlineMargin"
                >
                    Archive
                </Text>
            </GridContainer>

            {props.articles.map((_article) => (
                <BlogArchiveArticleRow
                    {..._article}
                    key={`archive-row-list::archive-row::${_article.Slug}`} // for some reason articles don't have ids???
                />
            ))}
        </>
    );
};
