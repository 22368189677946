/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiFeaturedArticle } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { pluralizeAuthorList } from "@/util/blog_util";
import { buildStylesByBreakpoint } from "@/util/style_util";

export const FeaturedArticle: FunctionComponent<StrapiFeaturedArticle> = ({
    Topic = { Name: "All" },
    ...props
}) => {
    /**
     * Styles
     */
    const featuredArticleHeroStyles = css({
        padding: `${Spacing["spacing-4"]} 0`,
        textDecoration: "none",
    });

    const featuredArticleInfoStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("order", {
            extraSmall: "2",
            medium: "1",
        }),
    );

    const featuredArticleLinkStyles = css({
        whiteSpace: "wrap",
    });

    const featuredArticleTopicStyles = css({
        alignItems: "center",
        display: "flex",
        gap: Spacing["spacing-2"],
        marginTop: "auto",
    });

    const featuredArticleImageStyles = css(
        buildStylesByBreakpoint("marginBottom", {
            extraSmall: Spacing["spacing-3"],
            medium: "auto",
        }),
        buildStylesByBreakpoint("order", {
            extraSmall: "1",
            medium: "2",
        }),
    );

    const chipStyles = css({
        border: "none",
        cursor: "pointer",
        display: "inline-flex",
    });

    /**
     * Elements
     */
    return (
        <GridContainer
            className={featuredArticleHeroStyles}
            href={`blog/${props.Slug}`}
            marginTop="FeaturedArticleHeroMargin"
            tag="a"
        >
            <GridColumn
                className={featuredArticleInfoStyles}
                columnSpan={{ extraSmall: 6, medium: 4, large: 4 }}
            >
                <Text
                    className={featuredArticleLinkStyles}
                    fontSize="FeaturedArticleHeadline"
                    marginBottom="FeaturedArticleHeroHeadlineMargin"
                    themeKey="headlinePrimary"
                >
                    {props.Title}
                </Text>

                <Text
                    fontSize="TextSmall"
                    marginBottom="FeaturedArticleAuthorMargin"
                    themeKey="textSecondary"
                >
                    By {pluralizeAuthorList(props.Authors)}
                </Text>

                <Text
                    className={featuredArticleTopicStyles}
                    fontSize="BlogTopic"
                    themeKey="textSecondary"
                >
                    Filed under
                    <Flag className={chipStyles} tag="button">
                        {Topic.Name}
                    </Flag>
                </Text>
            </GridColumn>

            <GridColumn
                className={featuredArticleImageStyles}
                columnSpan={{ extraSmall: 6, medium: 7, large: 8 }}
                columnStart={{ extraSmall: 1, medium: 6, large: 5 }}
            >
                <Picture
                    {...props.Hero_Media}
                    alternativeText={props.Title}
                    aspectRatio="2 / 1"
                    priority={true}
                />
            </GridColumn>
        </GridContainer>
    );
};
