/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiBlogTopic } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface BlogTopicProps {
    activeTopicName: string;
    onTopicClick?: (topicName: string) => void;
    topics?: StrapiBlogTopic[];
}

export const BlogTopicList: FunctionComponent<BlogTopicProps> = (props) => {
    /*
     * Interaction
     */
    const handleTopicClick = (topicName: StrapiBlogTopic["Name"] | "All") => {
        props.onTopicClick && props.onTopicClick(topicName);
    };

    /**
     * Styles
     */
    const topicLinksContainerStyles = css(
        {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
        },
        buildStylesByBreakpoint("gap", {
            extraSmall: Spacing["spacing-1"],
            medium: Spacing["spacing-2"],
        }),
    );

    const chipStyles = css({
        border: "none",
        display: "inline-flex",
    });

    /**
     * Elements
     */
    return (
        <GridContainer
            marginBottom="TopicChipListMargin"
            marginTop="TopicChipListMargin"
        >
            <GridColumn className={topicLinksContainerStyles} columnSpan="full">
                <Flag
                    backgroundColor={
                        props.activeTopicName === "All"
                            ? undefined
                            : "transparent"
                    }
                    className={chipStyles}
                    tag="button"
                    onClick={() => handleTopicClick("All")}
                >
                    All
                </Flag>

                {props.topics &&
                    props.topics.map((_topic) => {
                        return (
                            <Flag
                                backgroundColor={
                                    props.activeTopicName === _topic.Name
                                        ? undefined
                                        : "transparent"
                                }
                                className={chipStyles}
                                key={`topic-chip-list__topic-chip--${_topic.id}`}
                                tag="button"
                                onClick={() => handleTopicClick(_topic.Name)}
                            >
                                {_topic.Name}
                            </Flag>
                        );
                    })}
            </GridColumn>
        </GridContainer>
    );
};
