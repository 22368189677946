import type {
    GetStaticPropsContext,
    InferGetStaticPropsType,
    NextPage,
    PreviewData,
} from "next";
import { ParsedUrlQuery } from "querystring";
import { useState } from "react";

import { StrapiBlogTopic } from "@/types/strapi";

import { BlogTopicList } from "@/ui/organisms/blog_topic_list";
import { FeaturedArticle } from "@/ui/organisms/featured_article";
import { BlogArchiveArticleList } from "@/ui/wrappers/blog_archive_article_list";
import { BlogArticleCardList } from "@/ui/wrappers/blog_article_card_list";

import { generatePageLevelProps } from "@/util/data_util";
import { generateRssFeed } from "@/util/rss_util";
import { generateLegacyPreFooterData } from "@/util/strapi_data_util";
import {
    getBlogArticles,
    getBlogGlobals,
    getBlogHomePage,
    getGlobals,
} from "@/util/strapi_util";

export const getStaticProps = async ({
    preview: previewMode,
}: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const slug = "blog";

    const globalsData = await getGlobals(previewMode);
    const blogGlobalsData = await getBlogGlobals(previewMode);
    const pageData = await getBlogHomePage(previewMode);
    const blogArticlesData = await getBlogArticles(previewMode);

    generateRssFeed({ blogArticlesData, blogGlobalsData, globalsData });

    /**
     * Removes the featured article from the list if defined
     * and removes Article_Body (only used for RSS)
     */
    async function trimAndFilterBlogArticles() {
        return Promise.all(
            blogArticlesData
                .filter((article) => {
                    return (
                        pageData.Featured_Article &&
                        article.Slug !== pageData.Featured_Article.Slug
                    );
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .map(async ({ Article_Body, ...rest }) => {
                    return rest;
                }),
        );
    }

    const blogArticlesDataTrimmed = await trimAndFilterBlogArticles();

    return {
        props: {
            ...generatePageLevelProps({ path: `/${slug}`, previewMode, slug }),
            ...generateLegacyPreFooterData(globalsData),
            blogArchiveArticles: [...blogArticlesDataTrimmed.splice(12)],
            blogArticles: [...blogArticlesDataTrimmed.splice(0, 12)],
            blogGlobalsData,
            globalsData,
            pageData,
        },
    };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<PageProps> = (props) => {
    /**
     * State Management
     */
    const [activeTopic, setActiveTopic] = useState("All");

    const handleTopicClick = (topicName: StrapiBlogTopic["Name"] | "All") => {
        setActiveTopic(topicName);
    };

    /**
     * Elements
     */
    return (
        <>
            {props.pageData.Featured_Article && (
                <FeaturedArticle {...props.pageData.Featured_Article} />
            )}

            <BlogTopicList
                activeTopicName={activeTopic}
                topics={props.blogGlobalsData.Topics}
                onTopicClick={handleTopicClick}
            />

            <BlogArticleCardList
                articles={props.blogArticles.filter((_article) => {
                    if (activeTopic === "All") {
                        return true;
                    }
                    return _article.Topic?.Name === activeTopic;
                })}
            />

            <BlogArchiveArticleList
                articles={props.blogArchiveArticles.filter((_article) => {
                    if (activeTopic === "All") {
                        return true;
                    }
                    return _article.Topic?.Name === activeTopic;
                })}
            />
        </>
    );
};

export default Page;
