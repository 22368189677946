/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent, useState } from "react";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { pluralizeAuthorList } from "@/util/blog_util";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

import { BlogArticleSnippet } from "./blog_article_card";

export const BlogArchiveArticleRow: FunctionComponent<BlogArticleSnippet> = ({
    Topic = { Name: "All" },
    ...props
}) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * State Management
     */
    const [isHovering, setIsHovering] = useState(false);

    const toggleRowHover = () => {
        setIsHovering(!isHovering);
    };

    /**
     * Styles
     */
    const blogArticleLinkWrapperStyles = css({
        alignItems: "center",
        display: "block",
        textDecoration: "none",
    });

    const blogArticleRowContainerStyles = css({
        "&:before": {
            background: isHovering
                ? Colors[theme.backgrounds.backgroundSecondary]
                : "transparent",
            borderRadius: BorderRadiuses.borderLarge,
            content: "''",
            inset: `-${Spacing["spacing-3"]}`,
            position: "absolute",
            transition: "background 0.2s ease",
            zIndex: "-1",
        },
        "&:hover": {
            "&:before": {
                content: "''",
            },
        },
        borderRadius: convertToRem(BorderRadiuses.borderMedium),
        position: "relative",
        zIndex: "1",
    });

    const blogMainInfoContainerStyles = css(
        {
            alignItems: "center",
            display: "flex",
        },
        buildStylesByBreakpoint("gap", ColumnGaps),
    );

    const blogMetaInfoStyles = css({
        alignContent: "center",
    });

    const blogTopicContainerStyles = css(
        {
            alignContent: "center",
            alignItems: "center",
            display: "flex",
        },
        buildStylesByBreakpoint("order", {
            extraSmall: 3,
            medium: 0,
        }),
    );

    const blogImageStyles = css(
        {
            maxWidth: "10rem",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "block",
        }),
    );

    const chipStyles = css({
        border: "none",
        display: "inline-flex",
    });

    /**
     * Elements
     */
    return (
        <>
            <a css={blogArticleLinkWrapperStyles} href={`blog/${props.Slug}`}>
                <GridContainer
                    className={blogArticleRowContainerStyles}
                    onMouseEnter={toggleRowHover}
                    onMouseLeave={toggleRowHover}
                >
                    <GridColumn
                        className={blogMainInfoContainerStyles}
                        columnSpan={{ extraSmall: 6, medium: 7, large: 6 }}
                    >
                        <Picture
                            {...props.Hero_Media}
                            alternativeText={props.Title}
                            aspectRatio="2 / 1"
                            className={blogImageStyles}
                        />

                        <Text
                            fontSize="BlogArchiveListRowTitle"
                            marginBottom="ContactCardTitleMarginBottom"
                            tag="h3"
                        >
                            {props.Title}
                        </Text>
                    </GridColumn>

                    <GridColumn
                        className={blogTopicContainerStyles}
                        columnSpan={{ extraSmall: 3, medium: 2, large: 3 }}
                        columnStart={{ extraSmall: 4, medium: 8, large: 7 }}
                    >
                        <Flag className={chipStyles} tag="button">
                            {Topic.Name}
                        </Flag>
                    </GridColumn>

                    <Text
                        className={blogMetaInfoStyles}
                        color="lighten-70"
                        columnSpan={{ extraSmall: 3, medium: 3 }}
                        columnStart={{ extraSmall: 1, medium: 10 }}
                        fontSize="BlogArticleCardAuthors"
                    >
                        By {pluralizeAuthorList(props.Authors)}
                    </Text>
                </GridContainer>
            </a>

            <GridContainer>
                <GridColumn>
                    <Divider
                        marginBottom="BlogArchiveArticleRowDividerMargin"
                        marginTop="BlogArchiveArticleRowDividerMargin"
                    />
                </GridColumn>
            </GridContainer>
        </>
    );
};
