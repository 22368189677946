/** @jsxImportSource @emotion/react */
import { FunctionComponent } from "react";

import { ColumnStandardCount } from "@/types/tokens/grid";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import {
    BlogArticleCard,
    BlogArticleSnippet,
} from "@/ui/organisms/blog_article_card";

interface BlogArticleCardListProps {
    articles: BlogArticleSnippet[];
}

export const BlogArticleCardList: FunctionComponent<
    BlogArticleCardListProps
> = (props) => {
    /**
     * Elements
     */
    return (
        <GridContainer>
            {props.articles.map((_article, _index) => (
                <GridColumn
                    columnSpan={{
                        extraSmall: 6,
                        small: 3,
                        medium: 6,
                        large: 4,
                    }}
                    columnStart={{
                        extraSmall: 1,
                        small: _index % 2 === 0 ? 1 : 4,
                        medium: (6 * (_index % 2) + 1) as ColumnStandardCount,
                        large: (4 * (_index % 3) + 1) as ColumnStandardCount,
                    }}
                    key={`article-card-list::article-card::${_article.Slug}`} // no id so using slug
                    marginBottom="BlogArticleCardMargin"
                >
                    <BlogArticleCard {..._article} />
                </GridColumn>
            ))}
        </GridContainer>
    );
};
